<template>
  <div style="position: relative;">
    <div class="estate-right-view column" style="position:absolute;top:0px;right:0px;">
      <div class="estate-right-view-header flex s_center">
        <div class="flex1" style="position: relative;">
          <input @keyup.enter="handleSubmit" class="flex1" v-model="searchParams.name" placeholder="请试试输入楼盘名" />
          <a @click="handleSubmit" href="javascript:;" class="search-btn">
            <i class="el-icon-search white size14"></i>
          </a>
        </div>
      </div>
      <div class="estate-right-view-filter flex">
        <div @click="doShow('showFilter')" class="estate-right-view-filter-item flex1 flex  c_center cursor">
          <span>筛选条件</span>
          <i v-if="canShow.showFilter" class="up"></i>
          <i v-if="!canShow.showFilter" class="down"></i>
        </div>
        <div class="estate-right-view-filter-item flex1 flex  c_center cursor filter-sort relative"
          @mouseover="showSort = true" @mouseleave="showSort = false">
          <span :style="{
            color: sort.value.value !== '0' ? '#E64B4E' : '#101d37'
          }">{{ sort.value.value === "0" ? "默认排序" :
            sort.value.name }}</span>
          <i class="up"></i>
          <i class="down"></i>
          <ul v-show="showSort" class="estate-right-view-filter-sort">
            <li v-for="item in sort.option" :key="item.value" @click="() => onClickSort(item)" class="row c_center ">
              <span :style="{
                color: item.value === sort.value.value ? '#E64B4E' : '#101d37',
              }">{{
                item.name }}</span>
            </li>
          </ul>
        </div>
        <!-- 筛选条件 -->
        <div v-show="canShow.showFilter" class="estate-right-view-filter-div">
          <filter-view :source='filter' :index="3" @updateFilter='updateFilter'></filter-view>
        </div>
      </div>
      <!-- 楼盘列表 -->
      <div class="estate-right-view-estateList column flex1">
        <div class="estate-right-view-estateList-total">
          可视范围内共找到 {{ region2.length }} 个楼盘，共 {{ total }} 个
        </div>
        <!-- v-infinite-scroll="loadMore" -->
        <ul class="flex1 infinite-list list-container">
          <li v-for='(item) in region2' v-show="region2.length" :key='item.guid'>
            <estate-item :item='item' />
          </li>
          <li v-if="!region2.length" class="row c_center pv10">
            <!-- <span v-if="hasMore" style="color: #00ae66;">
              <i class="el-icon-loading"></i>
              <span class="size12 pl-5">加载中...</span>
            </span>
            <span v-else class="size14">已加载全部楼盘</span> -->
            <span class="size14">很抱歉，没有找到合适的房源，请重新查找</span>
          </li>
        </ul>
      </div>
    </div>
    <baidu-map @dragend="onDragendMap" @zoomend='setZoom' @ready="handler" @tilesloaded="onTilesLoaded" class="bm-view"
      auto-resize :min-zoom='11' :center="center" :zoom="zoom">
      <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
      <!-- 覆盖物 大区 -->
      <bm-overlay v-show="(10 < zoom && zoom <= 15 && item.lng && item.lat)" v-for='item in region' :key="item.key"
        pane="labelPane" class="region region_2" @draw="({ el, BMap, map }) => {
          const pixel = map.pointToOverlayPixel(new BMap.Point(item.lng, item.lat))
          el.style.left = pixel.x - 60 + 'px'
          el.style.top = pixel.y - 20 + 'px'
        }" @mouseover.native="active = true" @mouseleave.native="active = false">
        <div style="color:#fff;" @click="() => setZoom({}, 16, item)" class="column c_center">
          <div class="size14 mb5">{{ item.name }}</div>
          <div class="size12">{{ item.estateList.length + '个楼盘' }}</div>
        </div>
      </bm-overlay>
      <bm-overlay v-show="(10 < zoom && zoom > 15 && item.estateLng && item.estateLat)" v-for='(item) in region2'
        :key='item.key' pane="labelPane" class="region_3" @draw="({ el, BMap, map }) => {
          const pixel = map.pointToOverlayPixel(new BMap.Point(item.estateLng, item.estateLat))
          el.style.left = pixel.x - 60 + 'px'
          el.style.top = pixel.y - 20 + 'px'
        }" @mouseover.native="() => onRegion2MouseOver(item)" @mouseleave.native="onRegion2MouseLeave">
        <router-link target="_blank" :to="{ path: 'newEstateDetail', query: { guid: item.guid } }"
          class="bubble-wrap-link">
          <div v-show="showLabel || currentHoverGuid === item.guid" class="bubble-wrap">
            <div class="bubble-inner" style="z-index: 11;">
              <p class="name limit">{{ item.name }}</p>
              <p class="num">
                <span class="house-type">{{ item.propertyTypeDesc }}</span>均价
                <span class="price">{{ item.averagePrice || "价格待定"
                  }}</span>
                <i class="gt"> 元/平</i>
              </p>
              <i class="arrow">
                <i class="arrow-i"></i>
              </i>
            </div>
          </div>
        </router-link>

        <div v-show="showEstateName" class="bubble-wrap">
          <div class="bubble-inner" style="top:-38px">
            <p class="name limit">{{ item.name }}</p>
            <i class="arrow">
              <i class="arrow-i"></i>
            </i>
          </div>
        </div>
      </bm-overlay>
    </baidu-map>
  </div>
</template>

<script>
import EstateItem from "./components/estate-item";
import filterView from "./components/filter";
import HouseServer from "./../../services/houseServer";
import NewEstateService from "./../../services/newEstateService";
import { mapState } from "vuex";
import tool from "../../utils/tool";
import Constant from "@/common/Constant"
const pointCache = new Map()
export default {
  name: "setting",
  props: {},
  components: {
    "estate-item": EstateItem,
    "filter-view": filterView
  },
  data() {
    return {
      isFirstLoad: false,
      showSort: false,
      filter: [],
      canShow: {
        showFilter: false,
        showEstate: false
      },
      BMap: null,
      baiduMap: null,
      loading: false,
      center: {},
      zoom: 12,
      estateList: [],
      region2: [],
      searchParams: {
        cityCode: Constant.DEFAULT_CITY_CODE,
        page: 1,
        itemsPerPage: 10,
        sort: 0,
        type: 1, // 全部楼盘
        name: "",
        minPrice: 0,
        maxPrice: 0,
        minArea: 0,
        maxArea: 0,
        region: [],
        regionCode: [],
        room: [],
        propertyType: [], // 房屋类型
        sellState: [],
        tag: [],
        decorate: []
      },
      region: [], // 大区
      total: 0,
      hasMore: true,
      currentHoverGuid: "",
      showEstateName: false,
      showLabel: false
    };
  },
  computed: {
    sort() {
      const sort = this.filter.find(item => item.key === "sort") || {}
      sort.value = sort?.option?.[0] || {
        value: "0",
        name: "默认"
      }
      return sort
    },
    ...mapState(["location", "companyConfig"]),
  },
  methods: {
    onDragendMap() {
      tool.throttle(() => {
        this.getRegionData()
      }, 500)()
    },
    handleSubmit() {
      this.searchParams.page = 1
      this.loadEstateList(true)
    },
    // 参数
    async loadEstateParams() {
      const { cityCode } = this.location;
      try {
        const { data = {} } = await HouseServer.getNewEstateErpParams({ value: cityCode });
        let result = []

        if (data) {
          if (data.estateArea) {
            result.push({
              key: 'area',
              type: "single",
              name: '面积',
              value: {},
              option: data.estateArea,
            })
          }
          if (data.estateHouseType) {
            result.push({
              key: "room",
              name: "房型",
              type: "multiple",
              value: [],
              option: data.estateHouseType
            })
          }
          if (data.estateProperty) {
            result.push({
              key: "propertyType",
              name: "房屋类型",
              type: "multiple",
              value: [],
              option: data.estateProperty
            })
          }
          if (data.estateDecorate) {
            result.push({
              key: "decorate",
              name: "装修",
              type: "multiple",
              value: [],
              option: data.estateDecorate
            })
          }
          if (data.estateSellState) {
            result.push({
              key: "sellState",
              name: "状态",
              type: "multiple",
              value: [],
              option: data.estateSellState
            })
          }
          if (data.estateTag) {
            result.push({
              key: "tag",
              name: "特色",
              type: "multiple",
              value: [],
              option: data.estateTag
            })
          }
          if (data.estateSort) {
            result.push({
              key: 'sort',
              name: '排序',
              type: "sort",
              value: '',
              option: data.estateSort
            })
          }
          if (data.estateTotalPrice) {
            result.push({
              key: 'price',
              type: "slider-input",
              name: '总价',
              value: [0, 500],
              unit: "万",
              option: data.estateTotalPrice,
              selfValue: [0, 500],
            })
          }
        }
        this.filter = result;
        this.showSort = true
      } catch (e) {
        console.log("loadEstateParams :>> ", e);
      }
    },
    updateFilter({ filter, operate = "" }) {
      if (operate) {
        switch (operate) {
          case "取消":
            this.doShow("showFilter");
            break;
          case "确定":
            this.doShow("showFilter");
            this.filter.forEach(item => {
              if (item.key == "price") {
                const value = item.value
                this.searchParams.minPrice = value[0]
                this.searchParams.maxPrice = value[1]
              } else if (item.key === "area") {
                const value = item.value.value?.split?.("-") || []
                this.searchParams.minArea = value[0] || 0
                this.searchParams.maxArea = value[1] || 9999
              } else if (item.key !== "sort") {
                this.searchParams[item.key] = item.value.map(item => item.value)
              }
            })
            this.searchParams.page = 1
            this.loadEstateList()
        }
      }
    },
    getCoordinate() {
      const mapBounds = this.baiduMap.getBounds();
      const cen = mapBounds.getCenter();
      const northeast = mapBounds.getNorthEast();
      this.searchParams = {
        ...this.searchParams,
        centerLng: cen.lng,
        centerLat: cen.lat,
        northeastLng: northeast.lng,
        northeastLat: northeast.lat,
        cityCode: this.location.cityCode
      }
    },
    onTilesLoaded() {
      if (this.isFirstLoad) return
      this.isFirstLoad = true
      this.getCoordinate()
      this.loadEstateList()
      this.loadEstateListTotal()
    },
    async handler({ BMap, map }) {
      const { cityCode, cityName, latitude, longitude } = this.location;
      this.BMap = BMap;
      this.baiduMap = map;
      this.baiduMap.enableScrollWheelZoom(true);
      this.center = {
        lat: latitude,
        lng: longitude,
        city: cityName,
        cityCode
      };
    },
    setZoom({ target }, count = 0, item = {}) {
      if (count) {
        this.zoom = count;
        this.center = {
          ...this.center,
          lng: item.estateLng,
          lat: item.estateLat
        };
        return
      }
      const zoom = target.getZoom()
      this.zoom = zoom
      this.showLabel = zoom >= 17
      this.showEstateName = zoom >= 16 && zoom < 17
      this.onDragendMap()
    },
    // 城市列表
    async loadCityList() {
      try {
        const { data } = await HouseServer.getAllCityTree();
        this.cityList = data.cityTree;
      } catch (e) {
        console.log("loadCityList :>> ", e);
      }
    },
    // 触底加载
    loadMore() {
      if (this.loading) {
        return
      }
      this.searchParams.page++;
      this.loadEstateList();
    },
    getRegionData(isEstate) {
      const data = this.estateList
      const regionSet = new Set();
      const regMap = new Map();
      const boundsList = []
      data.forEach(item => {
        const { urbanName } = item;
        regionSet.add(urbanName);
        if (!regMap.has(urbanName)) {
          regMap.set(urbanName, []);
        }
        if (this.checkPoint(item.estateLng, item.estateLat)) {
          regMap.get(urbanName).push(item);
          boundsList.push({ ...item, key: item.guid + Date.now() })
        }
      });

      this.region = Array.from(regionSet).map((urbanName, index) => ({
        name: urbanName,
        estateList: regMap.get(urbanName),
        lat: regMap.get(urbanName)[0]?.lat || 0,
        lng: regMap.get(urbanName)[0]?.lng || 0,
        key: Date.now() + "" + index,
      }));
      this.region2 = boundsList
      if (isEstate && boundsList.length) {
        const first = boundsList[0]
        this.center = {
          ...this.center,
          lng: first.estateLng,
          lat: first.estateLat
        };
        this.zoom = 16
      }
    },
    // 楼盘
    async loadEstateList(isEstate = false) {
      const params = { ...this.searchParams }
      if (this.isLoading) return
      this.loading = true
      try {
        const res = await NewEstateService.getEstateMap(params);
        const { data } = res
        if (params.page == 1) {
          this.estateList = data;
        } else {
          this.estateList = [...this.estateList, ...data];
        }
        this.getRegionData(isEstate)
        if (data.length < params.itemsPerPage) {
          this.hasMore = false
        } else {
          this.hasMore = true
        }
      } catch (e) {
        console.log("getEstateMap :>> ", e);
      } finally {
        this.loading = false
      }
    },
    doShow(name) {
      if (name) {
        this.canShow[name] = !this.canShow[name];
      }
      for (const key of Object.keys(this.canShow)) {
        if (key != name) {
          this.canShow[key] = false;
        }
      }
      this.canShow = { ...this.canShow };
    },
    onRegion2MouseOver(item) {
      if (this.showLabel) return
      this.active = true
      this.currentHoverGuid = item.guid
    },
    onRegion2MouseLeave() {
      if (this.showLabel) return
      this.active = false
      this.currentHoverGuid = ""
    },
    onClickSort(value) {
      if (this.sort.value.value === value.value) return
      this.sort.value = value
      this.showSort = false
      this.searchParams.sort = value.value
      this.searchParams.page = 1
      this.loadEstateList()
    },
    checkPoint(lng, lat) {
      const cacheKey = `${lng},${lat}`;
      const bounds = this.baiduMap.getBounds();
      const point = pointCache.get(cacheKey) || new this.BMap.Point(lng, lat);
      if (!pointCache.has(cacheKey)) {
        pointCache.set(cacheKey, point);
      }
      return bounds.containsPoint(point);
    },
    async loadEstateListTotal() {
      const [err, res] = await tool.to(NewEstateService.getEstateList(this.searchParams))
      if (err) {
        return console.log('err getEstateList:>>', err);
      }
      this.total = res.data.total || 0
    }
  },
  created() {
    // this.loadCityList();
    this.loadEstateParams()
  }
};
</script>

<style scoped lang="less">
.bm-view {
  width: 100vw;
  height: calc(100vh - 38px);
}

.bubble-wrap-link {
  display: block;
  color: #000;
}



.filter-item {
  display: flex;
  flex: 1;
  justify-content: center;
  width: 86px;
}

.filter-view {
  background: #fff;
  border-radius: 4px;
  max-height: 500px;
  width: 505px;
  overflow-y: auto;
  position: absolute;
  left: 480px;
  top: 100px;
  padding: 20px 24px 20px;
}

.region {
  background: #5490F4;
  overflow: hidden;
  box-shadow: 0 0 2px #5490F4;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  &:hover {
    background-color: #E64B4E;
    box-shadow: 0 0 3px #E64B4E;
    transform: scaleY (1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
  }
}

.region_2 {
  width: 92px;
  height: 92px;
  border-radius: 50%;
}

.region_3 {
  background: url(../../assets/icon/city-circle.png) no-repeat 0 0;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: absolute;
}



.bubble-wrap {
  position: relative;
  font-size: 12px;
}

.bubble-wrap .bubble-inner {
  position: absolute;
  background-color: #ffffff;
  left: -9px;
  top: -62px;
  width: 160px;
  padding: 4px 0px;
  cursor: grab;
  z-index: 10;
  box-shadow: 0 10px 8px 0 rgba(0, 0, 0, .1)
}

.bubble-inner .name {
  height: 20px;
  line-height: 20px;
  font-weight: 800;
}

.bubble-inner p {
  padding: 2px 10px;
}

.bubble-inner .num {
  height: 20px;
  line-height: 20px;
  position: relative;
  z-index: 2;
}

.bubble-inner .house-type {
  font-weight: 800;
  margin-right: 6px;
}

.bubble-inner .price {
  font-family: Tahoma;
  color: #f15044;
  margin-left: 2px;
  margin-right: 2px;
  font-weight: 800;
}

.bubble-inner .gt {
  color: #999;
  // position: absolute;
  // right: 5px;
  font-size: 14px;
}

.arrow {
  position: relative;
  left: 9px;
  bottom: -15px;
}

.arrow-i {
  position: absolute;
  left: 9px;
  top: -16px;
  border: 8px solid transparent;
  border-left-color: #fff;
  border-top-color: #fff;
}


.estate-right-view {
  z-index: 500;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 400px;
  height: calc(100vh - 38px);
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);

  .estate-right-view-header {
    padding: 20px 25px;
    box-sizing: border-box;
    padding-bottom: 0;

    input {
      color: #333;
      border: 1px solid #dedede !important;
      display: block;
      width: 100%;
      height: 46px;
      margin: 0;
      padding: 0 0 0 22px;
      line-height: normal;
      font-size: 12px;
      box-sizing: border-box;
    }

    .search-btn {
      position: absolute;
      width: 50px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      top: 0;
      right: 0;
      padding: 0;
      margin: 0;
      background: #00ae66;
    }
  }

  .estate-right-view-filter {
    position: relative;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;

    .estate-right-view-filter-item {
      span {
        font-size: 12px;
        display: inline-block;
        max-width: 115px;
        line-height: 52px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        vertical-align: top;
      }

      .up {
        // display: inline-block;

        width: 0;
        height: 0;
        font-size: 0;
        border: 5px solid transparent;
        border-top-color: #dfdfdf;
        vertical-align: top;
        margin: -4px 0 0 4px;
        border-color: transparent transparent #999;
      }

      .down {
        // display: inline-block;
        margin: 6px 0 0 4px;
        width: 0;
        height: 0;
        font-size: 0;
        border: 5px solid transparent;
        border-top-color: #dfdfdf;
        vertical-align: top;
      }
    }

    .estate-right-view-filter-div {
      height: calc(100vh - 157px);
      background: #fff;
      position: absolute;
      top: 53px;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 20px 0 20px 20px;
      box-sizing: border-box;
      z-index: 10;
    }

  }

  .estate-right-view-estateList {
    overflow-y: scroll;

    .estate-right-view-estateList-total {
      color: #999;
      background-color: #f7f7f7;
      line-height: 35px;
      font-size: 12px;
      text-indent: 20px;

    }
  }

  .estate-right-view-filter-sort {
    position: absolute;
    top: 53px;
    left: 0;
    border: 1px solid #eee;
    width: 100%;
    font-size: 12px;
    background-color: #fff;
    z-index: 10;
    padding: 8px 0;
    display: none;
    box-sizing: border-box;
  }

  .estate-right-view-filter-sort li {
    padding: 8px 0;
    height: 12px;
  }

  .filter-sort:hover .estate-right-view-filter-sort {
    display: block;
  }

  .estate-right-view-filter-sort li:hover {
    background-color: #f5f5f5;
  }

  .filter-sort .down {
    display: block;
  }

  .filter-sort .up {
    display: none;
  }


}
</style>