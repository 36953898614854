<template>
  <div>
    <!-- 更多 -->
    <div class="filter flex1 column cursor">
      <div class="more-view flex1">
        <ul v-for="(item, _index) in data" :key="_index">
          <li v-if="item.type == 'slider-input'">
            <p style="margin-bottom:10px;">{{ item.title || item.name }}</p>
            <div class="slider-content">
              <el-slider v-model="item.value" range :min="0" :max="1000" />
            </div>
            <div class="input-content mb20">
              <div class="row s_center flex1">
                <el-input class="input" v-model="item.value[0]" @blur="clearInput"></el-input>
                <div style="width:10px;height:1px;margin:0 20px;background-color:#666;" />
                <el-input class="input" v-model="item.value[1]" @blur="clearInput"></el-input>
                <p v-if="item.unit" style="margin:0 20px;">{{ "( " + item.unit + " )" }}</p>
              </div>
            </div>
          </li>
          <li v-if="item.type == 'multiple'">
            <p style="margin-bottom:10px;">{{ item.title || item.name }}</p>
            <!-- 选项 -->
            <ul v-if="item.option.length" class="row s_start wrap mb20">
              <li class="row s_center item" v-for="(_item, _i) in item.option" :key="_i"
                @click="addItem(_item, _index)">
                <img v-if="isChoose(_item, _index)" class="box" src="./../../../assets/imgs/erp_icon_box_green.png" />
                <img v-if="!isChoose(_item, _index)" class="box"
                  src="./../../../assets/imgs/erp_icon_box_disable.png" />
                {{ _item.name }}
              </li>
            </ul>
          </li>
          <li v-if="item.type == 'single'">
            <p style="margin-bottom:10px;">{{ item.title || item.name }}</p>
            <!-- 选项 -->
            <ul v-if="item.option.length" class="row s_start wrap mb20">
              <li class="row s_center item" v-for="(_item, __index) in item.option" :key="__index"
                @click="addItem(_item, _index)">
                <img v-if="item.value && item.value.value == _item.value" class="box"
                  src="./../../../assets/imgs/erp_icon_box_green.png" />
                <img v-if="!(item.value && item.value.value == _item.value)" class="box"
                  src="./../../../assets/imgs/erp_icon_box_disable.png" />
                {{ _item.name }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- 按钮 -->
      <div class=" more-button">
        <div class="row e_center mt20">
          <a class=" more-button-clear" @click="clearMoreSelect">清空所有</a>
        </div>
        <div class="row c_center mt20 cursor">
          <div class="more-button-sure" @click="doOperate('确定')">确定</div>
          <div class="more-button-cancel" @click="doOperate('取消')">取消</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: Array,
    index: Number // 索引 <3 单个视图  >2 更多视图
  },
  data() {
    return {
      input: ""
    };
  },
  computed: {
    isChoose() {
      return (item, index = "") => {
        if (index) {
          if (
            this.data[index].value &&
            this.data[index].value instanceof Array
          ) {
            return this.data[index].value.some(ele => ele.value == item.value);
          }
        } else {
          if (this.data[0].value && this.data[0].value instanceof Array) {
            return this.data[0].value.some(ele => ele.value == item.value);
          }
        }
        return false;
      };
    },
    data() {
      return this.source
    }
  },
  methods: {
    addItem(item, index = "") {
      const setValue = targetIndex => {
        const obj = this.data[targetIndex];
        switch (obj.type) {
          case "multiple-input":
            obj.selfValue = {};
            if (!obj.value) {
              obj.value = [item];
            } else {
              const _length = obj.value.length;
              const result = obj.value.filter(ele => ele.value != item.value);
              if (_length == result.length) {
                // 新增
                obj.value = [...obj.value, item];
              } else {
                obj.value = [...result];
              }
            }
            break;
          case "multiple":
            if (!obj.value) {
              obj.value = [item];
            } else {
              const _length = obj.value.length;
              const result = obj.value.filter(ele => ele.value != item.value);
              if (_length == result.length) {
                // 新增
                obj.value = [...obj.value, item];
              } else {
                obj.value = [...result];
              }
            }
            break;
          case "single":
            obj.value = item;
            break;
        }
        this.data[targetIndex] = obj;
        this.$emit("updateFilter", { filter: this.data });
      };

      const _index = index;
      setValue(_index);
    },
    clearInput(index = "") {
      const setValue = targetIndex => {
        const obj = this.data[targetIndex];
        let min = obj.value[0]
        let max = obj.value[1]
        if (min && max) {
          if (min == max) {
            this.$message({
              type: "warning",
              message: "两次输入不能相同！"
            });
          } else if (min > max) {
            obj.value[0] = max;
            obj.value[1] = min;
          }
        }
        this.data[targetIndex] = obj;
        this.$emit("updateFilter", { filter: this.data });
      };
      setValue(index);

    },

    clearMoreSelect() {
      let result = [];
      this.data.forEach((item, index) => {
        if (item.type !== "sort") {
          if (item.type == "slider-input") {
            item.value = [0, 1000];
          } else if (item.type == "multiple") {
            item.value = [];
          } else {
            item.value = "";
          }
          result.push(item);
        }
      });
      this.$emit("updateFilter", { filter: result });
    },
    doOperate(operate) {
      this.$emit("updateFilter", { operate });
    }
  }
};
</script>

<style lang="less" scoped>
.filter {
  height: calc(100vh - 197px);
}

.more-view {
  height: calc(100vh - 307px);
  background: #fff;
  overflow: auto;
}

.more-button {
  padding-right: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 109px;
  border-top: 1px solid #ccc;

  .more-button-clear {
    display: inline-block;
    color: #666;
    font-size: 12px;
    text-decoration: underline;
  }

  .more-button-sure {
    color: #fff;
    background-color: #f15044;
    display: inline-block;
    width: 157px;
    height: 40px;
    border-radius: 2px;
    line-height: 40px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
  }

  .more-button-cancel {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    width: 157px;
    height: 40px;
    border-radius: 2px;
    line-height: 40px;
    text-decoration: none;
    color: #666;
    background-color: #f5f5f5;
  }
}

.item {
  width: 30%;
  padding: 0px 5px 10px 0px;
  color: rgba(0, 0, 0, 0.65);
}

.slider-content {
  width: 300px;
  padding-left: 10px;

  /deep/ .el-slider__bar {
    background: #00AE66 !important;
  }

  /deep/ .el-slider__button {
    border-color: #00AE66 !important;
  }
}

.input-content {
  padding-top: 10px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666;

  .input {
    width: 25%;
  }
}

.box {
  width: 13px;
  height: 13px;
  display: block;
  margin-right: 10px;
  border-radius: 2px;
}
</style>