<template>
  <router-link target="_blank" :to="{ path: 'newEstateDetail', query: { guid: item.guid } }">
    <div class="list-content row s_start">
      <div>
        <div class="relative">
          <img alt="封面图" class="image-size" :src="coverImage" />
          <span class="absolute img-tag">{{ item.propertyTypeDesc }}</span>
          <span class="cover-type">效果图</span>
        </div>
      </div>
      <div class="flex1" style="width:178px">
        <p class="title limit">{{ item.name }}</p>
        <p style="line-height: 3.4;">建面 {{ area }}㎡</p>
        <p>均价 <span class="price">{{ item.averagePrice || "价格待定" }}</span><i> 元/平</i></p>
      </div>
    </div>
  </router-link>
</template>

<script>
import Constant from "./../../../common/Constant";
export default {
  name: "house",
  props: {
    item: Object,
    currentTab: Number
  },
  data() {
    return {};
  },
  computed: {
    coverImage() {
      return this.item.coverImage || Constant.DEFAULT_HOUSE;
    },
    area() {
      const min = this.item.minBuildingArea
      const max = this.item.maxBuildingArea
      if (!min && !max) {
        return "面积待定"
      }
      return (min || 0) + '-' + (max || 999)
    }

  },
  filters: {
    returnImage(url) {
      return url + Constant.IMG_HZ
    }
  },
  watch: {},
  components: {},
  methods: {},
  created() { }
};
</script>

<style scoped lang="less">
@width: 30px;
@height: 80px;

.list-content {
  margin: 0 20px;
  padding: 20px 0;
  color: #181f24;
  font-size: 12px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;

  .cover-type {
    right: 30px;
    bottom: 12px;
    position: absolute;
    display: block;
    color: hsla(0, 0%, 100%, .5);
    font-size: 12px;
  }

  .price {
    font-family: Tahoma;
    font-weight: 800;
    font-size: 14px;
    color: #f15044;
  }

  .img-tag {
    left: 0;
    top: 0;
    display: inline-block;
    height: 12px;
    padding: 4px 5px;
    color: #fff;
    line-height: 12px;
    font-size: 12px;
    background: #424242;
    background: rgba(0, 0, 0, .5);
    border-bottom-right-radius: 5px;
  }

  .image-size {
    width: 120px;
    height: 102px;
    margin-right: 20px;
  }

  .title {
    font-size: 20px;
    color: #333333;
    font-weight: 700;
    line-height: 1;
  }

  .price {
    font-family: Tahoma;
    font-weight: 800;
    font-size: 14px;
    color: #f15044;
  }
}
</style>